
import Layout from "../components/Layout";
import { motion } from "framer-motion";
import { ExternalLink } from "lucide-react";
import { Separator } from "../components/ui/separator";

const Chalant = () => {
  return (
    <Layout>
      <div className="layout py-12">
        <motion.div 
          className="max-w-3xl mx-auto"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1 className="font-serif text-5xl font-medium mb-12 text-center">Chalant</h1>
          
          <div className="mb-12 rounded overflow-hidden">
            <img 
              src="https://i.pinimg.com/474x/34/30/f1/3430f152611172525de892b1f9bea36a.jpg" 
              alt="Chalant Company" 
              className="w-full h-auto"
            />
          </div>
          
          <div className="space-y-6 text-lg">
            <p>
              Chalant is a social network for fandoms.
            </p>
          
            <div className="flex items-center mt-4 group">
              <ExternalLink className="w-5 h-5 mr-3 text-charcoal group-hover:text-charcoal/70 transition-colors" />
              <a 
                href="https://chalant.net" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-charcoal hover:text-charcoal/70 transition-colors"
              >
                www.chalant.net
              </a>
            </div>
          </div>
        </motion.div>
      </div>
    </Layout>
  );
};

export default Chalant;
