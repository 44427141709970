
import { Link } from "react-router-dom";

interface PostCardProps {
  post: {
    title: string;
    slug: string;
    excerpt: string;
    date: string;
    coverImage: string;
  };
  index: number;
}

export default function PostCard({ post }: PostCardProps) {
  return (
    <div className="post-card animate-fade-in border border-taupe/30 overflow-hidden">
      <Link to={`/blog/${post.slug}`} className="block">
        <div className="image-container h-64 bg-[#d7d3cc]">
          <img 
            src={post.coverImage} 
            alt={post.title} 
            className="w-full h-full object-cover"
            loading="lazy"
          />
        </div>
        <div className="p-6 bg-[#FCF9F3]">
          <h3 className="text-xl font-serif mb-2 uppercase">{post.title}</h3>
          <p className="text-charcoal/90 text-sm leading-relaxed">{post.excerpt}</p>
        </div>
      </Link>
    </div>
  );
}
