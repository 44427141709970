
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Menu } from "lucide-react";
import { 
  Sheet,
  SheetContent,
  SheetTrigger
} from "@/components/ui/sheet";

export default function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Helper function for navigation links
  const NavItem = ({ to, children }: { to: string; children: React.ReactNode }) => (
    <NavLink 
      to={to} 
      className={({ isActive }) => 
        isActive ? "nav-link nav-link-active" : "nav-link"
      }
    >
      {children}
    </NavLink>
  );

  return (
    <header
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        isScrolled ? "py-3 bg-[#FCF9F3]/95 backdrop-blur-sm shadow-sm" : "py-6 bg-[#FCF9F3]"
      }`}
    >
      <div className="layout flex items-center justify-between">
        <NavLink to="/" className="font-serif text-3xl font-medium">
          BEKAH JUNE
        </NavLink>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex items-center space-x-8">
          <NavItem to="/chalant">Chalant</NavItem>
          <NavItem to="/work">Work</NavItem>
          <NavItem to="/contact">Contact</NavItem>
        </nav>

        {/* Mobile Menu Button and Sheet */}
        <Sheet>
          <SheetTrigger asChild>
            <button 
              className="md:hidden p-2 focus:outline-none" 
              aria-label="Toggle menu"
            >
              <Menu className="h-6 w-6" />
            </button>
          </SheetTrigger>
          <SheetContent side="right" className="pt-16 bg-[#FCF9F3]">
            <nav className="flex flex-col items-center justify-center space-y-8 h-full">
              <NavLink
                to="/"
                className="nav-link text-xl"
                onClick={() => document.body.click()} // Force Sheet to close
              >
                Home
              </NavLink>
              <NavLink
                to="/chalant"
                className="nav-link text-xl"
                onClick={() => document.body.click()} // Force Sheet to close
              >
                Chalant
              </NavLink>
              <NavLink
                to="/work"
                className="nav-link text-xl"
                onClick={() => document.body.click()} // Force Sheet to close
              >
                Work
              </NavLink>
              <NavLink
                to="/contact"
                className="nav-link text-xl"
                onClick={() => document.body.click()} // Force Sheet to close
              >
                Contact
              </NavLink>
            </nav>
          </SheetContent>
        </Sheet>
      </div>
    </header>
  );
}
