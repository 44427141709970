
import Layout from "../components/Layout";
import { motion } from "framer-motion";
import { Separator } from "@/components/ui/separator";
import { useIsMobile } from "../hooks/use-mobile";

interface WorkExperience {
  company: string;
  position: string;
  period: string;
  descriptions: string[];
}

const experiences: WorkExperience[] = [
  {
    company: "GITHUB",
    position: "DIRECTOR OF PRODUCT",
    period: "2022 - Present",
    descriptions: [
      "Drive collaboration between product, marketing, and engineering teams to deliver integrated solutions.",
      "Scaled Compute Products revenue to $100M+ ARR, implementing data-driven strategies.",
      "Develop and execute growth strategies focused on AI-powered tools and developer productivity."
    ]
  },
  {
    company: "HANDSOME",
    position: "LEAD SOLUTIONS ARCHITECT",
    period: "2021 - 2022",
    descriptions: [
      "Directed technical strategy sessions for enterprise clients, focusing on engineering best practices.",
      "Oversaw SRE and DevOps teams during leadership transitions, ensuring project continuity.",
      "Facilitated collaboration between creative directors and design teams to deliver cohesive solutions."
    ]
  },
  {
    company: "CIRCLECI",
    position: "SOLUTIONS ARCHITECT",
    period: "2020 - 2021",
    descriptions: [
      "Secured enterprise clients, driving $5M-$8M in revenue per quarter.",
      "Conducted competitive research and presented findings to C-suite executives and board members.",
      "Partnered with R&D and product teams to prioritize and implement customer-driven features."
    ]
  },
  {
    company: "VARIOUS STARTUPS",
    position: "SOFTWARE ENGINEER",
    period: "2016 - 2020",
    descriptions: [
      "Migrated and optimized web platforms, enhancing performance and security.",
      "Designed and implemented modular UI/UX systems for diverse client portfolios.",
      "Managed CI/CD pipelines and DevOps workflows to streamline development processes."
    ]
  }
];

const Work = () => {
  const isMobile = useIsMobile();
  
  return (
    <Layout>
      <div className="layout">
        <motion.div 
          className="max-w-3xl mx-auto text-center mb-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1 className="font-serif text-5xl mt-[2rem] font-medium mb-6">Resume</h1>
        </motion.div>
        
        <div className="max-w-4xl mx-auto mb-24">
          {experiences.map((exp, index) => (
            <motion.div 
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              className="relative"
            >
              {isMobile ? (
                // Mobile layout - vertical stacked design
                <div className="mb-16 relative pl-6 border-l-2 border-charcoal/30">
                  <div className="absolute left-[-8px] top-0">
                    <div className="w-4 h-4 rounded-full bg-charcoal"></div>
                  </div>
                  
                  <div className="mb-3">
                    <h3 className="font-serif text-xl font-medium tracking-wider">{exp.company}</h3>
                    <p className="text-sm text-charcoal/70 mt-1">{exp.period}</p>
                  </div>
                  
                  <div className="mb-4">
                    <h3 className="font-serif text-xl font-medium tracking-wider">{exp.position}</h3>
                  </div>
                  
                  <div className="space-y-4">
                    {exp.descriptions.map((desc, i) => (
                      <p key={i} className="text-charcoal/80 leading-relaxed">
                        {desc}
                      </p>
                    ))}
                  </div>
                </div>
              ) : (
                // Desktop layout - horizontal split design
                <div className="grid grid-cols-[1fr,1px,1fr] gap-8 mb-16">
                  {/* Left Column - Company */}
                  <div className="text-right self-start pt-2 pr-6">
                    <h3 className="font-serif text-xl font-medium tracking-wider">{exp.company}</h3>
                    <p className="text-sm text-charcoal/70 mt-1">{exp.period}</p>
                  </div>
                  
                  {/* Middle Column - Timeline */}
                  <div className="relative flex flex-col items-center">
                    <div className="w-4 h-4 rounded-full bg-charcoal z-10"></div>
                    <div className="w-px bg-charcoal/30 absolute top-4 bottom-[-64px]"></div>
                  </div>
                  
                  {/* Right Column - Position & Description */}
                  <div className="pl-6">
                    <h3 className="font-serif text-xl font-medium tracking-wider">{exp.position}</h3>
                    <div className="mt-4 space-y-4">
                      {exp.descriptions.map((desc, i) => (
                        <p key={i} className="text-charcoal/80 leading-relaxed">
                          {desc}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              
              {/* Hide the extending line for the last item */}
              {index === experiences.length - 1 && (
                <style>
                  {`.timeline-last::after { display: none; }`}
                </style>
              )}
            </motion.div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Work;
