
import { Link } from "react-router-dom";
import Layout from "../components/Layout";
import { motion } from "framer-motion";

const NotFound = () => {
  return (
    <Layout>
      <div className="layout min-h-[70vh] flex items-center justify-center">
        <motion.div 
          className="text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="font-serif text-7xl md:text-9xl font-medium mb-4">404</h1>
          <p className="text-xl text-charcoal/80 mb-8">
            The page you're looking for doesn't exist.
          </p>
          <Link to="/" className="btn">
            Return Home
          </Link>
        </motion.div>
      </div>
    </Layout>
  );
};

export default NotFound;
