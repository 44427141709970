// Configuration for GitHub
interface GithubConfig {
  owner: string;
  repo: string;
  branch: string;
  postsPath: string;
}

// Default configuration
const DEFAULT_CONFIG: GithubConfig = {
  owner: "yourusername", // Replace with your GitHub username
  repo: "blog-content", // Replace with your content repository name
  branch: "main",
  postsPath: "posts",
};

// Type definitions
export interface Post {
  title: string;
  slug: string;
  date: string;
  excerpt: string;
  content: string;
  coverImage: string;
  tags?: string[];
}

// Parse GitHub Markdown content with frontmatter
const parseFrontmatter = (content: string): { frontmatter: any; content: string } => {
  const frontmatterRegex = /---\s*([\s\S]*?)\s*---/;
  const match = frontmatterRegex.exec(content);
  
  if (!match) {
    return { frontmatter: {}, content };
  }

  const frontmatterBlock = match[1];
  const frontmatter: Record<string, any> = {};
  
  // Parse each line in frontmatter
  frontmatterBlock.split('\n').forEach(line => {
    const colonIndex = line.indexOf(':');
    if (colonIndex !== -1) {
      const key = line.slice(0, colonIndex).trim();
      const value = line.slice(colonIndex + 1).trim();
      
      // Handle arrays in frontmatter (like tags)
      if (value.startsWith('[') && value.endsWith(']')) {
        frontmatter[key] = value
          .slice(1, -1)
          .split(',')
          .map(item => item.trim().replace(/"/g, '').replace(/'/g, ''));
      } else {
        frontmatter[key] = value.replace(/"/g, '').replace(/'/g, '');
      }
    }
  });
  
  // Extract the content after frontmatter
  const contentWithoutFrontmatter = content.replace(frontmatterRegex, '').trim();
  
  return { frontmatter, content: contentWithoutFrontmatter };
};

// Known post files in the /posts directory
const knownPostFiles = [
  'hello-world.md',
  'test-1.md',
  'test-2.md',
  'test-3.md',
  'test-4.md',
  'test-5.md'
];

// Function to load posts from the /posts directory
async function loadPostsFromDirectory(): Promise<Post[]> {
  try {
    console.log("Loading posts from /posts directory...");
    
    // Fetch the index.json file to get the list of posts dynamically
    let postFiles: string[] = [];
    
    try {
      const indexResponse = await fetch('/posts/index.json');
      
      if (indexResponse.ok) {
        const indexData = await indexResponse.json();
        // Extract filenames from the index data
        postFiles = indexData.map((item: { filename: string }) => item.filename);
        console.log("Successfully loaded post index:", postFiles);
      } else {
        console.warn("Failed to fetch posts index, falling back to known files");
        postFiles = knownPostFiles;
      }
    } catch (indexError) {
      console.error("Error fetching posts index:", indexError);
      postFiles = knownPostFiles;
    }
    
    const posts: Post[] = [];
    
    // Fetch each post file via HTTP
    for (const filename of postFiles) {
      try {
        const response = await fetch(`/posts/${filename}`);
        
        if (response.ok) {
          const content = await response.text();
          const { frontmatter, content: postContent } = parseFrontmatter(content);
          
          if (frontmatter.title && frontmatter.date) {
            posts.push({
              title: frontmatter.title,
              slug: filename.replace(/\.md$/, ''),
              date: frontmatter.date,
              excerpt: frontmatter.excerpt || '',
              content: postContent,
              coverImage: frontmatter.coverImage || null,
              tags: frontmatter.tags || []
            });
          }
        }
      } catch (error) {
        console.error(`Error loading post ${filename}:`, error);
      }
    }
    
    // Sort posts by date (newest first)
    return posts.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  } catch (error) {
    console.error("Error loading posts from directory:", error);
    return [];
  }
}

// Fetch posts from local repository
export const fetchPosts = async (config = DEFAULT_CONFIG): Promise<Post[]> => {
  try {
    // Try to load posts from the /posts directory
    console.log('Trying to load posts from /posts directory...');
    const directoryPosts = await loadPostsFromDirectory();
    
    if (directoryPosts.length > 0) {
      console.log(`Successfully loaded ${directoryPosts.length} posts from directory`);
      return directoryPosts;
    }
    
    // If no posts found or an error occurred, fall back to mock posts
    console.log('No posts found in directory, using mock posts');
    // return mockPosts;
  } catch (error) {
    console.error('Error fetching posts:', error);
    // return mockPosts;
  }
};

// Fetch a single post by slug
export const fetchPostBySlug = async (slug: string, config = DEFAULT_CONFIG): Promise<Post | null> => {
  try {
    // Try to load the specific post from the /posts directory
    // First try the slug directly
    console.log(`Attempting to fetch post by slug: ${slug}`);
    
    // Try different file patterns (slug.md, test-*.md that might match)
    const possibleFiles = [
      `${slug}.md`,
      ...knownPostFiles.filter(file => file.startsWith(slug) || file.replace(/\.md$/, '') === slug)
    ];
    
    for (const filename of possibleFiles) {
      try {
        const postResponse = await fetch(`/posts/${filename}`);
        
        if (postResponse.ok) {
          const markdown = await postResponse.text();
          const { frontmatter, content } = parseFrontmatter(markdown);
          
          if (frontmatter.title && frontmatter.date && frontmatter.excerpt) {
            return {
              title: frontmatter.title,
              slug: slug,
              date: frontmatter.date,
              excerpt: frontmatter.excerpt,
              content: content,
              coverImage: frontmatter.coverImage || 'https://via.placeholder.com/800x400',
              tags: frontmatter.tags || []
            };
          }
        }
      } catch (error) {
        console.error(`Error trying to fetch ${filename}:`, error);
      }
    }
    
    // If post not found in directory or an error occurred, fall back to mock post
    console.log(`Post ${slug} not found in directory, checking mock posts`);
    // const post = mockPosts.find(post => post.slug === slug);
    // return post || null;
  } catch (error) {
    console.error(`Error fetching post ${slug}:`, error);
    // Fall back to mock post
    // const post = mockPosts.find(post => post.slug === slug);
    // return post || null;
  }
};

// Mock posts that would normally come from the /posts directory
// export const mockPosts: Post[] = [
//   {
//     title: "Building a Minimal Blog with GitHub CMS",
//     slug: "minimal-blog",
//     date: "2023-03-15",
//     excerpt: "Creating a beautiful minimal blog using GitHub as a CMS offers simplicity and elegance.",
//     content: "# Building a Minimal Blog\n\nThis is a sample post content. In a real application, this would be loaded from a Markdown file in the /posts directory of your repository.",
//     coverImage: "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80"
//   },
//   {
//     title: "The Power of Simplicity in Design",
//     slug: "simplicity-in-design",
//     date: "2023-03-10",
//     excerpt: "Why less is more when it comes to creating impactful designs that communicate clearly.",
//     content: "# Simplicity in Design\n\nThis is a sample post content. In a real application, this would be loaded from a Markdown file in the /posts directory of your repository.",
//     coverImage: "https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80"
//   },
//   {
//     title: "Typography Essentials for Web Design",
//     slug: "typography-essentials",
//     date: "2023-03-05",
//     excerpt: "The fundamental principles of typography that make or break your web design.",
//     content: "# Typography Essentials\n\nThis is a sample post content. In a real application, this would be loaded from a Markdown file in the /posts directory of your repository.",
//     coverImage: "https://images.unsplash.com/photo-1518770660439-4636190af475?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80"
//   },
//   {
//     title: "Creating Responsive Layouts",
//     slug: "responsive-layouts",
//     date: "2023-02-28",
//     excerpt: "How to build websites that look great on any device, from mobile to desktop.",
//     content: "# Responsive Layouts\n\nThis is a sample post content. In a real application, this would be loaded from a Markdown file in the /posts directory of your repository.",
//     coverImage: "https://images.unsplash.com/photo-1649972904349-6e44c42644a7?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80"
//   },
//   {
//     title: "Color Theory for Designers",
//     slug: "color-theory",
//     date: "2023-02-20",
//     excerpt: "Understanding how colors work together to create harmony in your designs.",
//     content: "# Color Theory\n\nThis is a sample post content. In a real application, this would be loaded from a Markdown file in the /posts directory of your repository.",
//     coverImage: "https://images.unsplash.com/photo-1541701494587-cb58502866ab?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80"
//   },
//   {
//     title: "The Art of Whitespace",
//     slug: "whitespace-design",
//     date: "2023-02-15",
//     excerpt: "Why empty space is one of the most powerful tools in a designer's toolkit.",
//     content: "# The Art of Whitespace\n\nThis is a sample post content. In a real application, this would be loaded from a Markdown file in the /posts directory of your repository.",
//     coverImage: "https://images.unsplash.com/photo-1461749280684-dccba630e2f6?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80"
//   }
// ];

// Function to set the GitHub configuration
export const setGithubConfig = (newConfig: Partial<GithubConfig>): GithubConfig => {
  return { ...DEFAULT_CONFIG, ...newConfig };
};
