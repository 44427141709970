import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Layout from "../components/Layout";
import { fetchPostBySlug, Post } from "../services/githubService";
import { marked } from "marked"; // Use marked instead of react-markdown
import { ArrowLeft } from "lucide-react";
import { motion } from "framer-motion";

// Configure marked options
marked.setOptions({
  breaks: true, 
  gfm: true
});

const BlogPost = () => {
  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = useState<Post | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getPost = async () => {
      if (!slug) return;
      
      setIsLoading(true);
      setError(null);
      
      try {
        const fetchedPost = await fetchPostBySlug(slug);
        
        if (fetchedPost) {
          setPost(fetchedPost);
          console.log("Loaded post:", fetchedPost);
        } else {
          setError("Failed to load post. It may not exist or there was an error fetching it.");
          console.error("No post found with slug:", slug);
          
          // Use placeholder data if GitHub fetch fails
          setPost({
            title: "Building a Minimal Blog with GitHub CMS",
            slug: slug,
            date: "2023-03-15",
            excerpt: "Creating a beautiful minimal blog using GitHub as a CMS offers simplicity and elegance.",
            content: `
# Building a Minimal Blog with GitHub CMS

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam viverra cubilia placerat commodo feugiat at. Habitasse habitant id congue euismod a duis. Id sollicitudin nec tincidunt quisque eget quis urna. Efficitur sed fames hac viverra curae montes. Phasellus dictum nostra amet suscipit porta vitae enim. Quisque rutrum feugiat efficitur mollis tempus

## Why Use GitHub as a CMS?

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam viverra cubilia placerat commodo feugiat at. 

- Version control for your content
- Work with markdown files
- No database required
- Free hosting options
- Simple deployment process

## Getting Started

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam viverra cubilia placerat commodo feugiat at. Habitasse habitant id congue euismod a duis. Id sollicitudin nec tincidunt quisque eget quis urna. 

### 1. Create a GitHub Repository

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam viverra cubilia placerat commodo feugiat at.

### 2. Structure Your Content

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam viverra cubilia placerat commodo feugiat at.

### 3. Connect to Your Website

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam viverra cubilia placerat commodo feugiat at.

## Conclusion

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam viverra cubilia placerat commodo feugiat at. Habitasse habitant id congue euismod a duis. Id sollicitudin nec tincidunt quisque eget quis urna. Efficitur sed fames hac viverra curae montes. Phasellus dictum nostra amet suscipit porta vitae enim. Quisque rutrum feugiat efficitur mollis tempus`,
            coverImage: "https://images.unsplash.com/photo-1499750310107-5fef28a66643?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80"
          });
        }
      } catch (error) {
        console.error("Error fetching post:", error);
        setError("An error occurred while loading the post.");
      } finally {
        setIsLoading(false);
      }
    };

    getPost();
  }, [slug]);

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  return (
    <Layout>
      <div className="layout pt-12 pb-12 md:pt-6">
        
        {isLoading ? (
          <div className="max-w-3xl mx-auto animate-pulse">
            <div className="h-10 bg-beige rounded w-3/4 mb-2"></div>
            <div className="h-6 bg-beige rounded w-1/4 mb-8"></div>
            <div className="h-96 bg-beige rounded w-full mb-8"></div>
            <div className="space-y-4">
              <div className="h-6 bg-beige rounded w-full"></div>
              <div className="h-6 bg-beige rounded w-5/6"></div>
              <div className="h-6 bg-beige rounded w-4/6"></div>
            </div>
          </div>
        ) : error ? (
          <div className="max-w-3xl mx-auto text-center bg-red-50 p-8 rounded">
            <h2 className="font-serif text-2xl text-red-700 mb-4">Error</h2>
            <p className="text-red-600">{error}</p>
            <Link to="/blog" className="btn mt-6">
              Return to Blog
            </Link>
          </div>
        ) : post ? (
          <motion.article 
            className="max-w-3xl mx-auto"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <header className="mb-8 mt-10 md:mt-4">
              <h1 className="font-serif text-3xl md:text-5xl font-medium mb-4">
                {post.title}
              </h1>
              <p className="text-charcoal/70">
                {formatDate(post.date)}
              </p>
            </header>
            
            <div className="mb-8 rounded overflow-hidden">
              <img 
                src={post.coverImage} 
                alt={post.title} 
                className="w-full h-auto"
              />
            </div>
            
            <div 
              className="blog-content prose prose-lg max-w-none" 
              dangerouslySetInnerHTML={{ __html: marked(post.content) }}
            />
          </motion.article>
        ) : null}
      </div>
    </Layout>
  );
};

export default BlogPost;
