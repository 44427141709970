
import Layout from "../components/Layout";
import { motion } from "framer-motion";
import { Github, Mail, MapPin, ExternalLink } from "lucide-react";
import { Separator } from "../components/ui/separator";

const Contact = () => {
  return (
    <Layout>
      <div className="layout">
        <motion.div 
          className="max-w-3xl mx-auto text-center mb-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1 className="font-serif text-5xl mt-[2rem] font-medium mb-6">About & Contact</h1>
          <p className="text-lg text-charcoal/80">
            👋🏼 Howdy, I'm Bekah. Let's get in touch!
          </p>
        </motion.div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 max-w-5xl mx-auto mb-24">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="space-y-6"
          >
            <h2 className="font-serif text-3xl font-medium mb-6">About Me</h2>
            
            <p className="text-charcoal/80">
              I’m a product leader, engineer, and designer with a strong background in front-end development, product partnerships, and business. With a keen eye for aesthetics and a strategic approach to problem-solving, I specialize in building thoughtful, impactful digital experiences.
            </p>
            
            <p className="text-charcoal/80">
              Currently, I’m focused on growing Chalant, where I’m shaping product vision and execution. I also take on select freelance projects, helping businesses craft compelling, high-quality digital solutions.
            </p>

            <div className="pt-4">
              <Separator className="my-8 bg-beige" />
              
              <div className="flex items-center">
                <MapPin className="w-5 h-5 mr-3 text-charcoal" />
                <span className="text-charcoal">Based in Austin, TX 🤠</span>
              </div>
            </div>
          </motion.div>
          
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="space-y-8"
          >
            <h2 className="font-serif text-3xl font-medium mb-6">Get in Touch</h2>
            
            <p className="text-charcoal/80 mb-8">
              I'm always open to discussing new projects, creative ideas, or opportunities to collaborate. Whether you have a question or just want to say hello, I'll try my best to get back to you as soon as possible.
            </p>
            
            <div className="space-y-6 mt-8">
              <div className="flex items-center group">
                <Mail className="w-5 h-5 mr-3 text-charcoal group-hover:text-charcoal/70 transition-colors" />
                <a 
                  href="mailto:hello@example.com" 
                  className="text-charcoal hover:text-charcoal/70 transition-colors"
                >
                  bekahjune@hey.com
                </a>
              </div>
              
              <div className="flex items-center group">
                <Github className="w-5 h-5 mr-3 text-charcoal group-hover:text-charcoal/70 transition-colors" />
                <a 
                  href="https://github.com/bekahjune" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-charcoal hover:text-charcoal/70 transition-colors"
                >
                  github.com/bekahjune
                </a>
              </div>
              
              <div className="flex items-center group">
                <ExternalLink className="w-5 h-5 mr-3 text-charcoal group-hover:text-charcoal/70 transition-colors" />
                <a 
                  href="https://linkedin.com/bekahjune" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-charcoal hover:text-charcoal/70 transition-colors"
                >
                  linkedin.com/in/bekahjune
                </a>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
