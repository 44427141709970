import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import PostCard from "../components/PostCard";
import { fetchPosts, Post } from "../services/githubService";
import { 
  Pagination, 
  PaginationContent, 
  PaginationItem, 
  PaginationLink
} from "@/components/ui/pagination";
import { useIsMobile } from "../hooks/use-mobile";

const Index = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 6;
  const isMobile = useIsMobile();

  useEffect(() => {
    const loadPosts = async () => {
      setIsLoading(true);
      setError(null);
      
      try {
        console.log("Home page: Fetching posts...");
        const fetchedPosts = await fetchPosts();
        console.log("Home page: Fetched posts:", fetchedPosts);
        setPosts(fetchedPosts);
      } catch (error) {
        console.error("Error loading posts:", error);
        setError("Failed to load blog posts.");
      } finally {
        setIsLoading(false);
      }
    };

    loadPosts();
  }, []);

  // Add null checks for posts
  const postsToDisplay = posts && posts.length 
    ? (isMobile 
        ? posts 
        : posts.slice((currentPage - 1) * postsPerPage, currentPage * postsPerPage))
    : [];

  // Calculate total number of pages (for desktop only)
  const totalPages = posts && posts.length ? Math.ceil(posts.length / postsPerPage) : 1;

  // Handle page changes
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    // Scroll to top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Generate page number buttons
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5; // Show 5 page numbers at once
    
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
    
    // Adjust start page if we're at the end
    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }
    
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <PaginationItem key={i}>
          <PaginationLink 
            isActive={i === currentPage}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
    
    return pageNumbers;
  };

  return (
    <Layout>
      {/* Blog Posts Grid */}
      <section className="mt-8 layout pt-4 pb-4 md:pt-10">
        {error && (
          <div className="mb-8 p-4 bg-red-50 text-red-800 rounded">
            {error}
          </div>
        )}
        
        {isLoading ? (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-10">
            {[1, 2, 3].map((i) => (
              <div key={i} className="post-card animate-pulse">
                <div className="bg-[#d7d3cc] h-64 w-full"></div>
                <div className="p-6 bg-[#FCF9F3]">
                  <div className="h-5 bg-beige rounded w-3/4 mb-3"></div>
                  <div className="h-4 bg-beige rounded w-full mb-2"></div>
                  <div className="h-4 bg-beige rounded w-2/3"></div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <>
            {postsToDisplay.length === 0 ? (
              <div className="text-center py-12">
                <p className="text-lg text-charcoal/80">No posts found. Check back soon!</p>
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-10">
                {postsToDisplay.map((post, index) => (
                  <PostCard key={post.slug} post={post} index={index} />
                ))}
              </div>
            )}
          </>
        )}
      </section>

      {/* Pagination - only shown on desktop */}
      {!isLoading && posts.length > 0 && !isMobile && (
        <section className="layout mb-8 mt-12">
          <Pagination>
            <PaginationContent>
              {renderPageNumbers()}
            </PaginationContent>
          </Pagination>
        </section>
      )}
    </Layout>
  );
};

export default Index;
